
import { defineComponent, ref, computed } from "vue";
import PolicyCancelRefundAction from "@/components/dropdown/car/PolicyCancelRefundAction.vue";
import PolicyService from "@/core/services/car/PolicyService";
import { notif } from "@/store/stateless/store";
import { useStore } from "vuex";
import ReusableModal from '@/components/modals/forms/ReusableModal.vue';
import { customFunction } from "@/store/stateless/store";

export default defineComponent({
  components: {
    PolicyCancelRefundAction,
    ReusableModal
  },

  props: {
    bodyParameters: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isRefundCompleted: {
      type: Boolean,
      required: false,
    },
    isRefundDeclined: {
      type: Boolean,
      required: false,
    },
  },

  setup(prop, context) {
    const currentPage = ref(1);
    const store = useStore();

    const refundPolicyBodyParam = ref({
      policy_id: null,
      refund_request_id: 0,
      refund_payable_amount: 0,
      refund_debit_amount: 0,
      refund_credit_amount: 0,
      refund_status: null,
      pay_to: 4,
      policy_status: 1,
      refund_account_notes: "",
      cancellation_status: "",
      invoice_id: 0,
    });


    const getPolicyList = (bodyParameters) => {
      currentPage.value = 1;
      PolicyService.getRefundPolicyList(bodyParameters.value);
    };

    let policyListObj = computed(() => {
      return store.getters.getpolicyList;
    });

    const showPolicyListDom = computed(() => {
      return store.getters.getPolicyListDom;
    });
    const loadModal = ref(false);
    const submitPolicyRefund = (policyObj, refundObj) => {
      refundPolicyBodyParam.value.policy_id = policyObj.id;
      refundPolicyBodyParam.value.refund_payable_amount =
        policyObj.car_refund_request?.refund_payable_amount_agent == 0
          ? policyObj.car_refund_request?.refund_payable_amount_customer
          : policyObj.car_refund_request?.refund_payable_amount_agent;

      refundPolicyBodyParam.value.refund_status =
        policyObj.car_refund_request?.refund_status;
      refundPolicyBodyParam.value.pay_to = policyObj.car_refund_request.pay_to;
  refundPolicyBodyParam.value.refund_request_id = policyObj.car_refund_request.id
      policyObj.is_loading = true;
      
      if(refundObj.category === 2) {
        refundPolicyBodyParam.value.invoice_id = refundObj.invoice_id;
      }

      Promise.all([
        PolicyService.refundPolicyApproval(refundPolicyBodyParam.value),
      ])
        .then((data) => {
          policyObj.is_loading = false;
          loadModal.value = false;
        
          if (!data[0].data.success && data[0].data.success != undefined) {
            let message = store.getters.getPolicyErrorDetails;
            
            // PolicyService.getRefundPolicyList(prop.bodyParameters);
          } else {
            PolicyService.getRefundPolicyList(prop.bodyParameters);
            notif.simple(`Policy Refund`, "success", data[0].message);
          }
          isOpen.value = false;
          refundItem.value = null;
        })
        .catch(() => {
          policyObj.is_loading = false;
          loadModal.value = false;
        });
    };


        const confirmEvent = (policyObj, refundObj) => {
          refundPolicyBodyParam.value.policy_status = 1;
      loadModal.value = true;
          submitPolicyRefund(policyObj, refundObj);
        };

    const cancelEvent = (policyObj, refundObj) => {
      refundPolicyBodyParam.value.policy_status = 2;
      submitPolicyRefund(policyObj, refundObj);
    };

    const onChangeRefundStatus = (event) => {
      refundPolicyBodyParam.value.refund_status = event;
    };

    // const payToList = computed(() => {
    //   return store.getters.getPayToList;
    // });

    const payToList = (policy, refundObj) => {
        const data = store.getters.getPayToList;

        if(refundObj.category === 1) {
      if(policy.transaction_type !== 1) {
        return data.filter(x => x.value !== 1)
      }
    }

      return data
    };

    const sendPolicyId = (e, i) => {
      context.emit("sendPolicyId", e, i);
    };

    const editPolicyRefundError = computed(() => {
      return store.getters.getEditPolicyRefundError;
    })

    const isOpen = ref(false);
    const openModal = (item, refundObj) => {
      refundItem.value = item;
      refObj.value = refundObj;
      isOpen.value = true;
    }
    const refundItem = ref(null);
    const refObj = ref(null);

    const close = () => {
      refundItem.value = null;
      isOpen.value = false;
    }

    const refundCategory = computed(() => {
      return store.getters.getCategoryRefund
    })

    return {
      showPolicyListDom,
      loadModal,
      close,
      refundItem,
      refObj,
      openModal,
      isOpen,
      editPolicyRefundError,
      currentPage,
      refundPolicyBodyParam,
      confirmEvent,
      onChangeRefundStatus,
      cancelEvent,
      submitPolicyRefund,
      sendPolicyId,
      payToList,
      policyListObj,
      refundCategory,
      customFunction
    };
  },
});
